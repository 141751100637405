import { library, config } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
	faArrowUp,
	faCalendarDays,
	faGlobe,
	faBars,
	faTimes,
	faStars,
	faEnvelope,
	faConciergeBell,
	faBlanket,
	faPhone,
} from '@fortawesome/pro-light-svg-icons';
import {
	faChevronUp,
	faChevronDown,
	faPhone as farPhone,
	faGift,
	faWineGlass,
} from '@fortawesome/pro-regular-svg-icons';
import {
	faMobileNotch,
	faBedFront,
	faBellConcierge,
	faBasketShopping,
	faXmark,
	faStar,
	faStarHalf,
} from '@fortawesome/pro-solid-svg-icons';
import { faFacebook, faGoogle, faInstagram, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons';

// This is important, we are going to let Nuxt.js worry about the CSS
config.autoAddCss = false;

library.add(
	faGoogle,
	faPhone,
	farPhone,
	faGlobe,
	faEnvelope,
	faArrowUp,
	faChevronDown,
	faChevronUp,
	faCalendarDays,
	faBasketShopping,
	faBars,
	faMobileNotch,
	faBellConcierge,
	faBedFront,
	faStars,
	faConciergeBell,
	faBlanket,
	faTimes,
	faFacebook,
	faInstagram,
	faYoutube,
	faLinkedin,
	faStar,
	faStarHalf,
	faXmark,
	faGift,
	faWineGlass,
);

export default defineNuxtPlugin(({ vueApp }) => {
	vueApp.component('font-awesome-icon', FontAwesomeIcon, {});
});
